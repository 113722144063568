import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LandingHeader from "../components/lading_page/header";
import LandingFooter from "../components/lading_page/footer";

const Policies = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, []);

  return (
    <>
      <LandingHeader />
      <div className="container privacy">
        <h1 style={{ textAlign: "center" }}>{t("POLICY")}</h1>
        <h3>{t("Privacy Policy")}</h3>
        <p>
          {t(
            "Welcome to our website. This Privacy Policy describes how we collect, use, and protect your personal information when you use our website. We are committed to protecting your privacy and ensuring the safety of your personal information for all visitors and users of our site. By continuing to use this website, you agree to our collection, use, and disclosure of your personal information as described in this policy."
          )}
        </p>
        <h3>{t("Personal Information We Collect")}</h3>
        <p>
          {t(
            "We may collect personal information from you through our website when you register, log in, fill out a form, participate in surveys, or interact with other services, features, or documents on the website. The personal information we collect may include your name, email address, phone number, home address, and other relevant information."
          )}
        </p>
        <h3>{t("Purpose of Using Personal Information")}</h3>
        <p>
          {t(
            "We use the personal information we collect from you to provide and manage the services, products, and documents you request from us. Your personal information may also be used to contact you, notify you, send you advertising or marketing information related to our services or other activities we believe you may be interested in."
          )}
        </p>
        <h3>{t("Personal Information Security")}</h3>
        <p>
          {t(
            "We are committed to protecting your personal information and apply reasonable security measures to ensure its safety and prevent unauthorized access, misuse, or disclosure of your personal information."
          )}
        </p>
        <h3>{t("Sharing Personal Information")}</h3>
        <p>
          {t(
            "We do not sell, trade, or share your personal information with third parties unless we have your permission or are required by law. However, we may share your personal information with trusted third parties to assist us in providing services to you, such as affiliate partners or service providers."
          )}
        </p>
        <h3>{t("Your Privacy Choices and Rights")}</h3>
        <p>
          {t(
            "You have the right to access, edit, or delete your personal information at any time by contacting us. You also have the right to opt out of our use of your personal information for marketing purposes or sending you advertising information. You can do this by unsubscribing or using the opt-out options provided in the emails we send you."
          )}
        </p>
        <h3>{t("External Links")}</h3>
        <p>
          {t(
            "Our website may contain links to external websites that we do not control or are not responsible for. This Privacy Policy does not apply to these external websites. Therefore, we recommend that you check the privacy policies of those websites before providing any personal information."
          )}
        </p>
        <h3>{t("Changes to Privacy Policy")}</h3>
        <p>
          {t(
            "We may update this Privacy Policy from time to time. Any changes will be communicated to you by posting a notice on the website or sending an email notification. By continuing to use the website after changes, you agree to the terms and conditions of the new Privacy Policy."
          )}
        </p>
      </div>
      <LandingFooter />
    </>
  );
};

export default Policies;
