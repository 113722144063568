import { Avatar } from "antd";
import { info } from "sass";
const JSONbig = require("json-bigint");

/*global chrome*/
const editorExtensionId = "cedkkgddfdhnlkkainimdeamjebkakfk";

const reqAPI = async (url, method, header, body, handleCors) => {
  try {
    const response = await new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        editorExtensionId,
        {
          method,
          url,
          body,
          header,
          handleCors,
        },
        resolve
      );
    });
    if (response) {
      let result;
      try {
        result = JSON.parse(response);
        return {
          obj: result,
        };
      } catch (error) {
        return {
          text: response,
        };
      }
    } else {
      return '{ "error": "101" }';
    }
  } catch (error) {
    console.log(error);
    return '{ "error": "101" }';
  }
};

const storageExtension = async (method, key, value) => {
  try {
    const response = await new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        editorExtensionId,
        {
          storage: true,
          method,
          key,
          value,
        },
        resolve
      );
    });

    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return '{ "error": "101" }';
  }
};

const updateAuthTT = (key, value) => {
  let authTT = localStorage.getItem("authTT");
  if (authTT) {
    authTT = JSON.parse(authTT);
    authTT[key] = value;
    localStorage.setItem("authTT", JSON.stringify(authTT));
    return authTT;
  }
  console.log("authTT no data");
};

const combinedArray = (array1, array2) => {
  return array1.map((obj1) => {
    const obj2 = array2.find((obj2) => obj2.id == obj1.id);
    if (obj2) {
      return { ...obj1, ...obj2 };
    }
    return obj1;
  });
};

const combinedArrayAd = (array1, array2) => {
  return array1.map((obj1) => {
    const obj2 = array2.find((obj2) => obj2.account_id == obj1.id);
    if (obj2) {
      return { ...obj1, ...obj2 };
    }
    return obj1;
  });
};

const getStatus = (numcode) => {
  let status = "";
  switch (numcode) {
    case 2:
      status = "active";
      break;
    case 4:
      status = "disable";
      break;
    case 8:
      status = "disable";
      break;
    default:
      console.log("status code undefined", numcode);
      status = "Unknow";
  }
  return status;
};

const getStatusBC = (numcode) => {
  let status = "";
  switch (numcode) {
    case 4:
      status = "active";
      break;
    case 8:
      status = "disable";
      break;
    default:
      console.log("status code undefined", numcode);
      status = "Unknow";
  }
  return status;
};

const getRoleBC = (numcode) => {
  let role = "";
  switch (numcode) {
    case 1:
      role = "Admin";
      break;
    default:
      role = "Unknow";
      console.log("role code undefined", numcode);
  }
  return role;
};

const getRole = (numcode) => {
  let role = "";
  switch (numcode) {
    case 0:
      role = "Admin";
      break;
    default:
      role = "Unknow";
      console.log("role code undefined", numcode);
  }
  return role;
};

function convertTimestamp(timestamp) {
  if (timestamp === "-") {
    return "-";
  }
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const filter_list_bc = (arrData) => {
  let flieds = [
    "status",
    "id",
    "name",
    "adv_count_in_bc",
    "payment_method",
    "verified",
    "currency",
    "creator_id",
    "role",
    "two_step_verify_status",
    "timezone",
    "create_time",
  ];
  return arrData.map((obj, stt) => {
    const filteredObj = {};
    filteredObj.stt = stt + 1;
    flieds.forEach((key) => {
      switch (key) {
        case "status":
          filteredObj[key] = getStatusBC(obj[key]);
          break;
        case "role":
          filteredObj[key] = getRoleBC(obj[key]);
          break;
        case "payment_method":
          filteredObj[key] = obj[key] == "1" ? "Manual" : "Automatic";
          break;
        case "create_time":
          filteredObj[key] = convertTimestamp(obj[key]);
          break;
        default:
          filteredObj[key] = obj[key] || "-";
      }
    });
    return filteredObj;
  });
};

const filter_list_ad = (arrData) => {
  let flieds = [
    "status",
    "id",
    "name",
    "balance",
    "account_balance",
    "bill_balance",
    "debt_balance",
    "stat_cost",
    "show_cnt",
    "click_cnt",
    "time_attr_convert_cnt",
    "currency",
    "next_bill_time",
    "role",
    "company",
    "owner_bc_id",
    "tax",
    "country",
    "timezone",
    "create_time",
  ];
  return arrData.map((obj, stt) => {
    const filteredObj = {};
    filteredObj.stt = stt + 1;
    flieds.forEach((key) => {
      switch (key) {
        case "status":
          filteredObj[key] = getStatus(obj[key]);
          break;
        case "role":
          filteredObj[key] = getRole(obj[key]);
          break;
        case "next_bill_time":
          filteredObj[key] = convertTimestamp(obj[key]);
          break;
        case "account_balance":
          filteredObj[key] =  obj[key] || 0
          break;
        default:
          filteredObj[key] = obj[key] || "-";
      }
    });
    return filteredObj;
  });
};

const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const ChromeTask = {
  sendTask: async function (message) {
    try {
      const response = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          editorExtensionId,
          {
            message,
          },
          resolve
        );
      });
      if (response) {
        return response;
      } else {
        return "not install";
      }
    } catch (error) {
      return "not install";
    }
  },
  storageExtension: async function (method, key, value) {
    let data = await storageExtension(method, key, value);
    return data;
  },
  get_profile_info: async function () {
    let endpoint = `https://ads.tiktok.com/passport/web/account/info/`;
    let res = await reqAPI(endpoint, "GET");
    return {
      name: res?.obj?.data?.screen_name || "",
      picture: res?.obj?.data?.avatar_url || false,
    };
  },
  getListAccount: async function () {
    let endpoint = `https://ads.tiktok.com/api/v2/i18n/account/account_switch_list/`;
    let res = await reqAPI(endpoint, "GET");
    if (res?.obj?.code !== 0) {
      return null;
    }
    let list_bc = [];
    let list_ad_persional = [];
    for (let ac of res.obj.data.data) {
      if ("adv_count_in_bc" in ac) {
        list_bc.push(ac);
      } else {
        list_ad_persional.push(ac);
      }
    }
    let profileInfo = await this.get_profile_info();

    let authTT = {
      name: profileInfo.name,
      picture: profileInfo.picture,
      list_bc,
      list_ad_persional,
    };
    localStorage.setItem("authTT", JSON.stringify(authTT));
    return authTT;
  },

  get_list_bc: async function (auth) {
    let fetchesInfo = [];
    let fetchesPayment = [];

    for (var bc of auth.list_bc) {
      fetchesInfo.push(this.get_info_bc(bc.id));
      fetchesPayment.push(this.get_payment_method_bc(bc.id));
    }
    let dataInfo = await Promise.all(fetchesInfo);
    let dataPaymentMethod = await Promise.all(fetchesPayment);
    let arrResult = combinedArray(dataInfo, dataPaymentMethod);
    arrResult = combinedArray(auth.list_bc, arrResult);
    arrResult = filter_list_bc(arrResult);
    updateAuthTT("load_bc", true);
    return updateAuthTT("list_bc", arrResult);
  },
  get_info_bc: async function (bc_id) {
    let endpoint = `https://business.tiktok.com/api/v2/bm/organization/detail/?org_id=${bc_id}`;
    let res = await reqAPI(endpoint, "GET");
    if ("data" in res?.obj) {
      return res?.obj?.data;
    }
    return null;
  },
  get_payment_method_bc: async function (bc_id) {
    let endpoint = `https://business.tiktok.com/api/v2/bm/payment/info/?org_id=${bc_id}`;
    let res = await reqAPI(endpoint, "GET");
    if ("data" in res?.obj) {
      return {
        id: bc_id,
        payment_method: res?.obj.data.payment_method,
      };
    }
    return null;
  },
  get_limit_bc: async function (id_bc) {
    let endpoint = `https://business.tiktok.com/api/v2/bm/integrity/account/quota/?org_id=${id_bc}`;
    let res = await reqAPI(endpoint, "GET");
    if (res?.obj?.code !== 0) {
      return null;
    }
    return res?.obj?.data?.total_quota;
  },
  get_list_ad: async function (auth) {
    let res_ad_persional = await this.get_list_ad_persional(
      auth.list_ad_persional
    );
    let res_ad_in_bc = await this.get_list_ad_in_bc(auth.list_bc);
    const concatArrAd = res_ad_persional.concat(res_ad_in_bc);
    const mergedArray = concatArrAd.reduce((acc, obj) => {
      const existingObj = acc.find((item) => item.id === obj.id);
      if (existingObj) {
        Object.assign(existingObj, obj);
      } else {
        acc.push({ ...obj });
      }
      return acc;
    }, []);
    let arr_info_etc = await this.get_info_etc_ad(mergedArray);
    let result = combinedArray(mergedArray, arr_info_etc);
    result = filter_list_ad(result);

    return updateAuthTT("list_ad", result);
  },

  get_info_etc_ad: async function (list_ad) {
    let arrStat = [];
    let arrThreshold = [];
    for (let acc of list_ad) {
      let start_time = acc.create_time.slice(0, 10);
      arrStat.push(this.get_stat_ad(acc.id, start_time));
      arrThreshold.push(this.get_threshold_ad(acc.id));
    }
    let dataStat = await Promise.all(arrStat);
    let dataThreshold = await Promise.all(arrThreshold);
    let result = combinedArray(dataStat, dataThreshold);
    return result;
  },
  get_threshold_ad: async function (ad_id) {
    let endpoint = `https://ads.tiktok.com/api/v3/i18n/payment/credit/info/?aadvid=${ad_id}`;
    let res = await reqAPI(endpoint, "GET");
    return {
      id: ad_id,
      debt_balance: res?.obj?.data.current_bill?.debt_balance || "0",
      bill_balance: res?.obj?.data?.bill_balance || "0",
      next_bill_time: res?.obj?.data?.next_bill_time || "-",
    };
  },
  get_stat_ad: async function (ad_id, start_time) {
    const csrf_token = await this.storageExtension("get", "ad_csrftoken");
    let header = {
      "content-type": "application/json;charset=UTF-8",
      "X-Csrftoken": csrf_token,
    };
    let end_time = getToday();
    let body = {
      query_list: [
        "stat_cost",
        "show_cnt",
        "click_cnt",
        "time_attr_convert_cnt",
      ],
      start_time: start_time,
      end_time: end_time,
      lifetime: 1,
    };
    let endpoint = `https://ads.tiktok.com/api/v4/i18n/statistics/dashboard/advertiser/stat/?aadvid=${ad_id}`;
    let res = await reqAPI(endpoint, "POST", header, body);
    let table = Array.isArray(res?.obj?.data?.table)
      ? res?.obj?.data?.table
      : null;
    return {
      id: ad_id,
      stat_cost: table ? table[0]?.value : "0",
      show_cnt: table ? table[1]?.value : "0",
      click_cnt: table ? table[2]?.value : "0",
      time_attr_convert_cnt: table ? table[3]?.value : "0",
    };
  },

  get_list_ad_in_bc: async function (listBC) {
    let arrInfo = [];
    let arrPayment = [];
    for (let bc of listBC) {
      arrInfo.push(this.get_info_ad_in_bc(bc.id));
      arrPayment.push(this.get_payment_ad_in_bc(bc.id));
    }
    let dataInfo = await Promise.all(arrInfo);
    dataInfo = dataInfo.flat();
    let dataPayment = await Promise.all(arrPayment);
    dataPayment = dataPayment.flat();
    let combiArr = combinedArray(dataInfo, dataPayment);
    return combiArr;
  },
  get_list_ad_persional: async function (list_ad_persional) {
    let arrInfo = [];
    let arrPayment = [];
    for (let ad of list_ad_persional) {
      arrInfo.push(this.get_info_ad_persional(ad.id));
      arrPayment.push(this.get_payment_ad_persional(ad.id));
    }
    let dataInfo = await Promise.all(arrInfo);
    dataInfo = dataInfo.flat();
    let dataPayment = await Promise.all(arrPayment);
    dataPayment = dataPayment.flat();
    let combiArr = combinedArrayAd(dataInfo, dataPayment);
    return combiArr;
  },
  get_info_ad_persional: async function (ad_id) {
    let endpoint = `https://ads.tiktok.com/api/v3/i18n/account/permission/detail/?aadvid=${ad_id}`;
    let res = await reqAPI(endpoint, "GET");
    return res?.obj?.data?.account || null;
  },
  get_payment_ad_persional: async function (ad_id) {
    let endpoint = `https://ads.tiktok.com/api/v3/i18n/statistics/transaction/balance/query/?aadvid=${ad_id}&source=3`;
    let res = await reqAPI(endpoint, "GET");
    return res?.obj?.data | null;
  },

  get_info_ad_in_bc: async function (bc_id) {
    let endpoint = `https://business.tiktok.com/api/v2/bm/asset/account/list/?org_id=${bc_id}&page=1&limit=100`;
    let res = await reqAPI(endpoint, "GET");
    if ("data" in res?.obj) {
      const updatedArray = res?.obj.data.advs.map((obj) => ({
        ...obj,
        owner_bc_id: bc_id,
      }));
      return updatedArray;
    }
    return null;
  },
  get_payment_ad_in_bc: async function (ad_id) {
    const csrf_token = await this.storageExtension("get", "bc_csrftoken");
    let endpoint = `https://business.tiktok.com/api/v3/bm/statistics/payment/adv_account_list/?org_id=${ad_id}&attr_source=&source_biz_id=&attr_type=web`;
    let header = {
      "content-type": "application/json;charset=UTF-8",
      "X-Csrftoken": csrf_token,
    };
    let body = {
      limit: 1000,
      page: 1,
      query_list: [
        "advertiser_id",
        "advertiser_name",
        "show_tax_type",
        "balance_reminder_v2",
        "balance_reminder",
        "grant_balance_abs",
        "tcm_info",
        "account_type",
        "status",
        "billing_setting_bc_name",
        "billing_setting_bc_id",
        "bs_ref_status",
        "billing_setting_status",
        "has_billing_permission",
        "upay_cards",
        "total_balance_valid",
        "total_balance_abs",
        "company_name",
        "currency",
        "adv_contacter",
        "adv_create_time",
        "account_days",
        "first_cash",
        "first_recharge_time",
        "latest_recharge_time",
        "recharge_amount",
        "recharge_count",
        "credit_cash_abs",
        "credit_cash_valid",
        "grant_balance_valid",
        "prepay_cash_abs",
        "prepay_cash_valid",
        "deal_base_cash_abs",
        "deal_base_cash_valid",
        "adv_country",
        "adv_id",
        "shared_type",
        "display_timezone",
        "coupon_valid_balance",
        "transfer_stat",
        "budget",
        "budget_cost",
        "budget_mode",
        "result_state",
        "disable_reason",
        "current_cost",
        "bill_balance",
        "next_bill_time",
        "high_risk_tag",
        "frozen_balance",
        "total_tax",
        "qualification_demand_status",
      ],
    };

    let res = await reqAPI(endpoint, "POST", header, body);
    return res?.obj?.data?.advertisers || null;
  },
};

export default ChromeTask;
