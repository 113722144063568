import { useTranslation } from "react-i18next";

const InToLogin = (props) => {
  const { t } = useTranslation();

  const tryagain = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="tool-bar-error">
        <div className="icon">
          <i className="fa-solid fa-gear"></i>
        </div>
        <div className="link-text">{t("Not login")}</div>
      </div>
      <div className="tab-content">
        <div className="install">
          <img src="/assets/image/loginfb.png" alt="install"></img>
          <h4>{t("Not login")}</h4>
          <p>{t("Please login to business center Tiktok")}</p>
          <button className="btn_common" onClick={() => tryagain()}>
            Try again
          </button>
        </div>
      </div>
    </>
  );
};

export default InToLogin;
