import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authTT: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTT: (state, action) => {
      state.authTT = action.payload;
    },
    clearAuthTT: (state) => {
      state.authTT = null;
    },
  },
});

export const { setAuthTT, clearAuthTT } = authSlice.actions;

export default authSlice.reducer;
