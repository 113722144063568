import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { uniqBy } from "lodash";
import { render } from "@testing-library/react";

const TableAd = (props) => {
  const { search, listAd } = props;
  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 50,
    pageSize: 50,
    position: ["bottomCenter"],
    pageSizeOptions: [50, 100, 200, 500, 2000],
    total: listAd.length,
    showSizeChanger: true,
    showTotal: (total) => `Total: ${total}`,
  });
  const [sortedInfo, setSortedInfo] = useState({});
  const [dataPageCurrent, setDataPageCurrent] = useState([]);

  useEffect(() => {
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    const currentDataSource = listAd.slice(
      startIndex,
      startIndex + pagination.pageSize
    );
    setDataPageCurrent(currentDataSource);
    setPagination({
      current: 1,
      defaultPageSize: 50,
      pageSize: 50,
      position: ["bottomCenter"],
      pageSizeOptions: [50, 100, 200, 500, 2000],
      total: listAd.length,
      showSizeChanger: true,
      showTotal: (total) => `Total: ${total}`,
    });
  }, [listAd]);

  const handleChange = (pagi, filters, sorter, extra) => {
    let paginationCurrent = {};
    if (extra.action === "filter") {
      paginationCurrent = pagination;
    } else {
      paginationCurrent = pagi;
    }

    setPagination(paginationCurrent);
    const startIndex =
      (paginationCurrent.current - 1) * paginationCurrent.pageSize;
    const currentDataSource = listAd.slice(
      startIndex,
      startIndex + paginationCurrent.pageSize
    );
    setDataPageCurrent(currentDataSource);

    setSortedInfo(sorter);
  };

  const extractUniqueValues = (dataSource, dataIndex) => {
    return uniqBy(dataSource, dataIndex).map((item) => ({
      text: item[dataIndex],
      value: item[dataIndex],
    }));
  };

  function sortAz09(a, b) {
    if (isNaN(a) && isNaN(b)) {
      return b.localeCompare(a);
    } else if (!isNaN(a) && !isNaN(b)) {
      return b - a;
    } else {
      return isNaN(a) ? 1 : -1;
    }
  }

  const searchFilter = (keySearch, record) => {
    const searchableFields = [
      "id",
      "name",
      "status",
      "balance",
      "account_balance",
      "bill_balance",
      "debt_balance",
      "stat_cost",
      "show_cnt",
      "click_cnt",
      "time_attr_convert_cnt",
      "currency",
      "next_bill_time",
      "role",
      "company",
      "owner_bc_id",
      "tax",
      "country",
      "timezone",
      "create_time",
    ];
    const searchString = keySearch.toLowerCase();

    return searchableFields.some((field) =>
      String(record[field]).toLowerCase().includes(searchString)
    );
  };

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "g-status";
      case "disable":
        return "r-status";
      case "close":
        return "b-status";
      case "needPay":
        return "y-status";
      case "period":
        return "p-status";
      default:
        return "d-status";
    }
  };

  const columns = [
    {
      title: "no",
      dataIndex: "stt",
      key: "stt",
      sorter: (a, b) => sortAz09(a.stt, b.stt),
      sortOrder: sortedInfo.columnKey === "stt" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "50px",
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className={`status ${formatStatus(record.status)}`}>
          <div>{text}</div>{" "}
        </div>
      ),
      sorter: (a, b) => sortAz09(a.status, b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      onFilter: (value, record) => searchFilter(value, record),
      sorter: (a, b) => sortAz09(a.id, b.id),
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "Link",
      key: "Action",
      render: (text, record) => (
        <div className="flex_s">
          <a
            href={`https://ads.tiktok.com/i18n/dashboard/?aadvid=${record.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-solid fa-ellipsis"></i>
          </a>
        </div>
      ),
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => sortAz09(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },

    {
      title: "debt_balance",
      dataIndex: "debt_balance",
      key: "debt_balance",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) => sortAz09(a.debt_balance, b.debt_balance),
      sortOrder:
        sortedInfo.columnKey === "debt_balance" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        if (text !== "-") {
          return Number(text).toLocaleString("en-US")?.replace(".", ".");
        } else {
          return Number(record.account_balance)
            .toLocaleString("en-US")
            ?.replace(".", ".");
        }
      },
      sorter: (a, b) => sortAz09(a.balance, b.balance),
      sortOrder: sortedInfo.columnKey === "balance" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "Threshold",
      dataIndex: "bill_balance",
      key: "bill_balance",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) => sortAz09(a.bill_balance, b.bill_balance),
      sortOrder:
        sortedInfo.columnKey === "bill_balance" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },

    {
      title: "spend",
      dataIndex: "stat_cost",
      key: "stat_cost",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) => sortAz09(a.stat_cost, b.stat_cost),
      sortOrder: sortedInfo.columnKey === "stat_cost" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "impressions",
      dataIndex: "show_cnt",
      key: "show_cnt",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) => sortAz09(a.show_cnt, b.show_cnt),
      sortOrder: sortedInfo.columnKey === "show_cnt" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "Click",
      dataIndex: "click_cnt",
      key: "click_cnt",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) => sortAz09(a.click_cnt, b.click_cnt),
      sortOrder: sortedInfo.columnKey === "click_cnt" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "convert",
      dataIndex: "time_attr_convert_cnt",
      key: "time_attr_convert_cnt",
      render: (value, record) =>
        Number(value)?.toLocaleString("en-US")?.replace(".", ".") || "?",
      sorter: (a, b) =>
        sortAz09(a.time_attr_convert_cnt, b.time_attr_convert_cnt),
      sortOrder:
        sortedInfo.columnKey === "time_attr_convert_cnt"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => sortAz09(a.currency, b.currency),
      sortOrder: sortedInfo.columnKey === "currency" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "next_bill_time",
      dataIndex: "next_bill_time",
      key: "next_bill_time",
      sorter: (a, b) => sortAz09(a.next_bill_time, b.next_bill_time),
      sortOrder:
        sortedInfo.columnKey === "next_bill_time" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "account type",
      render: (text, record) =>
        record.owner_bc_id === "-" ? "Persional" : "BC",
      key: "owner_bc_id",
      sorter: (a, b) => sortAz09(a.owner_bc_id, b.owner_bc_id),
      sortOrder:
        sortedInfo.columnKey === "owner_bc_id" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => sortAz09(a.currency, b.currency),
      sortOrder: sortedInfo.columnKey === "currency" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => sortAz09(a.role, b.role),
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },

    {
      title: "company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => sortAz09(a.company, b.company),
      sortOrder: sortedInfo.columnKey === "company" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "owner_bc_id",
      dataIndex: "owner_bc_id",
      key: "owner_bc_id",
      sorter: (a, b) => sortAz09(a.owner_bc_id, b.owner_bc_id),
      sortOrder:
        sortedInfo.columnKey === "owner_bc_id" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },

    {
      title: "tax",
      dataIndex: "tax",
      key: "tax",
      sorter: (a, b) => sortAz09(a.tax, b.tax),
      sortOrder: sortedInfo.columnKey === "tax" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "country",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => sortAz09(a.country, b.country),
      sortOrder: sortedInfo.columnKey === "country" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },

    {
      title: "timezone",
      dataIndex: "timezone",
      key: "timezone",
      sorter: (a, b) => sortAz09(a.timezone, b.timezone),
      sortOrder: sortedInfo.columnKey === "timezone" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "create_time",
      dataIndex: "create_time",
      key: "create_time",
      sorter: (a, b) => sortAz09(a.create_time, b.create_time),
      sortOrder:
        sortedInfo.columnKey === "create_time" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataPageCurrent}
        onChange={handleChange}
        tableLayout="auto"
        pagination={pagination}
        size="small"
        scroll={{
          x: false,
        }}
      />
    </>
  );
};
export default TableAd;
