import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AppMain from "../pages/Main";
import NotFound from "./notfound";

const App = () => {
  return (
    <>
      <div className="main-app">
        <Header />
        <div className="containers">
          <Routes>
            <Route path="/" element={<AppMain />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default App;
