import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./pages/App";
import LandingPage from "./pages/LandingPage";
import LandingHeader from "./components/lading_page/header";
import Polices from "./pages/policy";

const Home = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/app/*" element={<App />} />
        <Route path="/policy" element={<Polices />} />
      </Routes>
    </>
  );
};

export default Home;
