import { useTranslation } from "react-i18next";

const Install = (props) => {
  const { t } = useTranslation();
  const openLink = () => {
    window.open(
      "https://chromewebstore.google.com/detail/tiklite/cedkkgddfdhnlkkainimdeamjebkakfk",
      "_blank"
    );
  };
  return (
    <>
      <div className="tool-bar-error">
        <div className="icon">
          <i className="fa-solid fa-gear"></i>
        </div>
        <div className="link-text">{t("install")}</div>
      </div>
      <div className="tab-content">
        <div className="install">
          <img src="assets/image/dltiklite.png" alt="install"></img>
          <h4>{t("Install TikLite extension")}</h4>
          <p>
            {t(
              "To continue using, please install the tiklite extension from chrome webstore then reload the website!"
            )}
          </p>
          <button onClick={openLink}>
            <i className="fa-brands fa-chrome"></i>&nbsp;
            {t("Download from chrome webstore")}
          </button>
        </div>
      </div>
    </>
  );
};
export default Install;
