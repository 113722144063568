import "./datepicker.scss";
import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;

const DatePickerSelect = (props) => {
  const {
    setShowDatePicker,
    getDataInRangeTime,
    selectedRange,
    setSelectedRange,
  } = props;
  const [fromDate, setFromDate] = useState(
    selectedRange[0].format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(selectedRange[1].format("YYYY-MM-DD"));
  const [isOpen, setOpen] = useState(false);
  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedRange(dates);
      setFromDate(dateStrings[0]);
      setToDate(dateStrings[1]);
      // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      setShowDatePicker(false);
    }
  };

  const getRangeTime = () => {
    setOpen(false);
    getDataInRangeTime(fromDate, toDate); 
  };

  const handlePresetClick = (value, i) => {
    try {
      if (value[0] === "") {
        setShowDatePicker(false);
        getDataInRangeTime("", "");
        return;
      }
    } catch (error) {}
  };

  const rangePresets = [
    {
      label: "All",
      value: ["", ""],
    },
    {
      label: "Today",
      value: [dayjs().startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().subtract(6, "days").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Last 30 Days",
      value: [
        dayjs().subtract(29, "days").startOf("day"),
        dayjs().endOf("day"),
      ],
    },
    {
      label: "Last 90 Days",
      value: [
        dayjs().subtract(89, "days").startOf("day"),
        dayjs().endOf("day"),
      ],
    },
    {
      label: "This week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last week",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "This year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
  ];

  return (
    <>
      <Space direction="vertical" size={12}>
        <RangePicker
          onCalendarChange={handlePresetClick}
          presets={rangePresets}
          open={isOpen}
          onFocus={() => setOpen(true)}
          value={selectedRange}
          onChange={onRangeChange}
          size="small"
          separator={"•"}
          renderExtraFooter={() => {
            return (
              <>
                <div className="datepicker_footer">
                  <p>
                    {fromDate} → {toDate}
                  </p>
                  <div className="control">
                    <span
                      className="c"
                      onClick={() => setShowDatePicker(false)}
                    >
                      Cancel
                    </span>
                    <span className="ok" onClick={() => getRangeTime()}>
                      Apply
                    </span>
                  </div>
                </div>
              </>
            );
          }}
          onOk={() => {}}
        />
      </Space>
    </>
  );
};

export default DatePickerSelect;
