
const NotFound = () => {

    return (
        <>
            <h2>Page Not Found 404</h2>
        </>
    )
}

export default NotFound;