import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAuthTT } from "../redux/authSlice";
import dayjs from "dayjs";
import ChromeTask from "../api/engineck";
import * as XLSX from "xlsx";
import DatePickerSelect from "../components/DatePicker/DatePicker";
import TableAd from "../components/tables/tableAD";
import TableBC from "../components/tables/TableBC";
import Loading from "../components/Loading";
import InToLogin from "./IntoLogin";

const MainContent = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.authTT);
  const [authorization, setAuthozization] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [listAd, setListAd] = useState([]);
  const [listBc, setListBc] = useState([]);
  const [isTabAd, setIsTabAd] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isMix, setMix] = useState(true);
  const [loadExport, setLoadExport] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedRange, setSelectedRange] = useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    async function fetchData() {
      checkLogin();
    }
    fetchData();
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    if (auth) {
      getAdlist(auth);
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    async function fetchData() {
      if (!auth) {
        return;
      }
      switch (activeTab) {
        case 1:
          getAdlist(auth);
          break;
        case 2:
          getBCList(auth);
          break;
        // case 3:
        //   break;
        default:
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const checkLogin = async () => {
    let auth = localStorage.getItem("authTT");
    try {
      auth = JSON.parse(auth);
    } catch (error) {
      auth = null;
    }
    if (auth?.list_bc) {
      dispatch(setAuthTT(auth));
      return;
    }
    auth = await ChromeTask.getListAccount();
    if (auth) {
      dispatch(setAuthTT(auth));
      return;
    }
    setAuthozization(false);
    clearAllData();
  };

  const clearAllData = () => {
    localStorage.removeItem("authTT");
  };

  const getBCList = async (auth) => {
    if (auth.list_bc) {
      setListBc(auth.list_bc);
      if (!auth.load_bc) {
        let data = await ChromeTask.get_list_bc(auth);
        dispatch(setAuthTT(data));
        setListBc(data.list_bc);
      }
      setLoading(false);
    }
  };

  const getAdlist = async (auth) => {
    setLoading(true);
    if (auth.list_ad) {
      setListAd(auth.list_ad);
      setLoading(false);
      return;
    }
    let data = await ChromeTask.get_list_ad(auth);
    if (data) {
      dispatch(setAuthTT(data));
      setListAd(data.list_ad);
    }
    setLoading(false);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 1) {
      setIsTabAd(true);
    } else {
      setIsTabAd(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleExport_ex = async () => {
    var res = 1;
    if (res.data.error) {
      toast.error(res.data.error);
      return;
    } else if (res.data.auth) {
      toast.error("Vui lòng đăng nhập để sử dụng tính năng này");
      return;
    }

    if (loadExport) {
      return;
    }
    setLoadExport(true);
    toast.info("Đang xuất dữ liệu vui lòng chờ!");
    const ex = 1;
    const workbook = XLSX.utils.book_new();
    for (let d of ex) {
      if (d.data) {
        let sheet = XLSX.utils.json_to_sheet(d.data);
        XLSX.utils.book_append_sheet(workbook, sheet, d.name);
      }
    }
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAsExcel(excelBuffer, "sMeta Report.xlsx");
    setLoadExport(false);
  };

  function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(deepClone);
    }
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepClone(value)])
    );
  }

  const saveAsExcel = (buffer, fileName) => {
    const data = new Blob([buffer], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(data);
    link.download = fileName;
    link.click();
  };

  const reloadData = async (activeTab) => {
    setLoading(true);
    let objAuth = deepClone(auth);
    switch (activeTab) {
      case 1:
        delete objAuth.list_bc;
        delete objAuth.load_bc;
        delete objAuth.list_ad_persional;
        setListBc([]);
        break;

      default:
    }
    localStorage.setItem("authTT", JSON.stringify(objAuth));
    dispatch(setAuthTT(objAuth));
    setReload(!reload);
  };

  const getDataInRangeTime = async (start, end, all) => {
    return;
  };

  return (
    <>
      {authorization ? (
        <>
          <div className="tool-bar">
            <div className="left">
              <div className="tab-list">
                <div
                  className={activeTab === 1 ? "tab active-tab" : "tab"}
                  onClick={() => handleTabClick(1)}
                >
                  AD
                </div>
                <div
                  className={activeTab === 2 ? "tab active-tab" : "tab"}
                  onClick={() => handleTabClick(2)}
                >
                  BC
                </div>
                {/* <div
                    className={activeTab === 3 ? "tab active-tab" : "tab"}
                    onClick={() => handleTabClick(3)}
                  >
                    CAMP
                  </div> */}
              </div>
              <div className="search">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  onChange={handleSearchChange}
                  placeholder="Search"
                  value={searchKeyword}
                />
              </div>
            </div>
            <div className="right">
              {/* {showDatePicker ? (
                <DatePickerSelect
                  setShowDatePicker={setShowDatePicker}
                  getDataInRangeTime={getDataInRangeTime}
                  selectedRange={selectedRange}
                  setSelectedRange={setSelectedRange}
                />
              ) : (
                <div
                  className="btn"
                  style={{
                    width: "auto",
                    padding: `${showDatePicker ? 0 : ""}`,
                  }}
                  onClick={(e) => {
                    setShowDatePicker(!showDatePicker);
                  }}
                >
                  <i className="fa-regular fa-calendar"></i>
                </div>
              )} */}

              <div className="btn" onClick={() => reloadData(activeTab)}>
                <i className="fa-solid fa-rotate"></i>
              </div>
            </div>
          </div>

          <div className="tab-content relative">
            {!loading ? (
              <>
                <div
                  className={
                    activeTab === 1 ? "content active-content" : "content"
                  }
                >
                  <TableAd search={searchKeyword} listAd={listAd} />
                </div>
                <div
                  className={
                    activeTab === 2 ? "content active-content" : "content"
                  }
                >
                  <TableBC search={searchKeyword} listBC={listBc} />
                </div>
                <div
                  className={
                    activeTab === 3 ? "content active-content" : "content"
                  }
                >
                  {/* <TableAd search={"searchKeyword"} listAd={listAd} /> */}
                </div>
              </>
            ) : (
              <Loading />
            )}
          </div>
        </>
      ) : (
        <InToLogin />
      )}
    </>
  );
};

export default MainContent;
