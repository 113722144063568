import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function LandingFooter() {
  return (
    <>
      <div className="footer_landing">
        <div className="product">
          <h4>TIKLITE.COM</h4>
          <p> Tiklite copyright 2024</p>
        </div>
        <div className="quicklink">
          <h4>QUICK LINK</h4>
          <ul>
            <li>
              <a href="/policy">Policy</a>
            </li>
          </ul>
        </div>
        <div className="contact">
            <h4>CONTACT</h4>
            <p>Email: support@Tiklite.com</p>
        </div>
      </div>
    </>
  );
}

export default LandingFooter;
