import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/pagination";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import LandingHeader from "../components/lading_page/header";
import LandingFooter from "../components/lading_page/footer";
import Aos from "aos";

const LandingPage = () => {
  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const openLinkInNewTab = () => {
    console.log(111);
    const url =
      "https://chromewebstore.google.com/detail/tiklite/cedkkgddfdhnlkkainimdeamjebkakfk";
    window.open(url, "_blank");
  };

  return (
    <>
      <LandingHeader />
      <div className="lading-content">
        <section className="one">
          <div className="one-content">
            <div className="text-one">
              <h1>
                Manage advertising accounts and business center accounts easily
                and professionally
              </h1>
            </div>
            <div className="img-one">
              <img src="assets/image/tiklite_cover.png" alt="" />
            </div>
          </div>
        </section>
        <section className="one">
          <div className="one-content">
            <button onClick={openLinkInNewTab}>Download from webstore</button>
          </div>
        </section>
        <section className="one">
          <div className="one-content">
            <ul>
              <li>
                <h4>Comprehensive Dashboard</h4>
                <p>
                  Get an instant overview of all your TikTok ad accounts and
                  business center details in one place.
                </p>
              </li>
              <li>
                <h4>Efficient Account Management</h4>
                <p>
                  Easily add, edit, and monitor your ad accounts and business
                  center profiles without any hassle.
                </p>
              </li>
              <li>
                <h4>Seamless Integration</h4>
                <p>
                  Our extension integrates smoothly with TikTok’s API, providing
                  a seamless user experience.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </div>
      <LandingFooter />
    </>
  );
};

export default LandingPage;
