import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer-left">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://t.me/tiklite_official", "_blank")}
        >
          <i className="fa-brands fa-telegram"></i> Support
        </div>
      </div>
      <div className="footer-right">
        <p>© 2024 TikLike. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
