import React, { useState, useEffect } from "react";
import ChromeTask from "../api/engineck";
import MainContent from "./Main-content";
import Install from "./Install";

const AppMain = () => {
  const [isInstall, setInstall] = useState(true);

  useEffect(() => {
    async function fetchData() {
      checkInstall();
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const checkInstall = async () => {
    let checkInstall = await ChromeTask.sendTask("checkinstall");
    if (checkInstall === "not install") {
      setInstall(false);
      return;
    }
  };

  return (
    <>
      {isInstall ? (
        <>
          <MainContent />
        </>
      ) : (
        <Install />
      )}
    </>
  );
};

export default AppMain;
