import React from "react";
import ChromeTask from "../api/engine";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthTT } from "../redux/authSlice";

const Header = () => {
  const auth = useSelector((state) => state.auth.authTT);

  return (
    <header>
      <nav>
        <ul>
          <li className="menu">
            <i className="fa-solid fa-bars"></i>
          </li>
          <li>
            <div className="app-logo">
              <img src="/assets/image/logo-while.png" alt="tiklite" />
            </div>
          </li>
          <li>
            <div className="bcinfo">
              {auth ? (
                <>
                  <img src={auth?.picture || '/assets/image/profile.png'} alt="pciture" />
                  <p>{auth?.name}</p>
                </>
              ) : (
                <p>Not connected</p>
              )}
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
