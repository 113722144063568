import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { uniqBy } from "lodash";
import { render } from "@testing-library/react";

const TableBC = (props) => {
  const { search, listBC } = props;
  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 50,
    pageSize: 50,
    position: ["bottomCenter"],
    pageSizeOptions: [50, 100, 200, 500, 2000],
    total: listBC.length,
    showSizeChanger: true,
    showTotal: (total) => `Total: ${total}`, // Hiển thị số trang trên tổng số trang
  });
  const [sortedInfo, setSortedInfo] = useState({});
  const [dataPageCurrent, setDataPageCurrent] = useState([]);

  useEffect(() => {
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    const currentDataSource = listBC.slice(
      startIndex,
      startIndex + pagination.pageSize
    );
    setDataPageCurrent(currentDataSource);
    setPagination({
      current: 1,
      defaultPageSize: 50,
      pageSize: 50,
      position: ["bottomCenter"],
      pageSizeOptions: [50, 100, 200, 500, 2000],
      total: listBC.length,
      showSizeChanger: true,
      showTotal: (total) => `Total: ${total}`, // Hiển thị số trang trên tổng số trang
    });
  }, [listBC]);

  const handleChange = (pagi, filters, sorter, extra) => {
    let paginationCurrent = {};
    if (extra.action === "filter") {
      paginationCurrent = pagination;
    } else {
      paginationCurrent = pagi;
    }

    setPagination(paginationCurrent);
    const startIndex =
      (paginationCurrent.current - 1) * paginationCurrent.pageSize;
    const currentDataSource = listBC.slice(
      startIndex,
      startIndex + paginationCurrent.pageSize
    );
    setDataPageCurrent(currentDataSource);

    setSortedInfo(sorter);
  };

  const searchFilter = (keySearch, record) => {
    const searchableFields = [
      "status",
      "id",
      "name",
      "adv_count_in_bc",
      "payment_method",
      "verified",
      "currency",
      "creator_id",
      "role",
      "two_step_verify_status",
      "timezone",
      "create_time",
    ];
    const searchString = keySearch.toLowerCase();

    return searchableFields.some((field) =>
      String(record[field]).toLowerCase().includes(searchString)
    );
  };

  const extractUniqueValues = (dataSource, dataIndex) => {
    return uniqBy(dataSource, dataIndex).map((item) => ({
      text: item[dataIndex],
      value: item[dataIndex],
    }));
  };

  function sortAz09(a, b) {
    if (isNaN(a) && isNaN(b)) {
      return b.localeCompare(a);
    } else if (!isNaN(a) && !isNaN(b)) {
      return b - a;
    } else {
      return isNaN(a) ? 1 : -1;
    }
  }

  const formatStatus = (status) => {
    switch (status) {
      case "active":
        return "g-status";
      case "disable":
        return "r-status";
      case "close":
        return "b-status";
      case "needPay":
        return "y-status";
      case "period":
        return "p-status";
      default:
        return "d-status";
    }
  };

  const columns = [
    {
      title: "no",
      render: (text, record, index) => index + 1,
      key: "stt",
      filteredValue: [search],
      ellipsis: true,
      align: "center",
      minWidth: "50px",
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className={`status ${formatStatus(record.status)}`}>
          <div>{text}</div>{" "}
        </div>
      ),
      filteredValue: [search],
      onFilter: (value, record) => searchFilter(value, record),
      sorter: (a, b) => sortAz09(a.status, b.status),
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },

    {
      title: "id",
      dataIndex: "id",
      key: "id",
      filteredValue: [search],
      sorter: (a, b) => sortAz09(a.id, b.id),
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      onFilter: (value, record) => searchFilter(value, record),
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "Link",
      key: "Action",
      render: (text, record) => (
        <div className="flex_s">
          <a
            href={`https://business.tiktok.com/manage/overview?org_id=${record.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-solid fa-ellipsis"></i>
          </a>
        </div>
      ),
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      filteredValue: [search],
      sorter: (a, b) => sortAz09(a.name, b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      width: "5",
    },
    {
      title: "Ad account",
      dataIndex: "adv_count_in_bc",
      key: "adv_count_in_bc",
      filteredValue: [search],
      sorter: (a, b) => sortAz09(a.adv_count_in_bc, b.adv_count_in_bc),
      sortOrder:
        sortedInfo.columnKey === "adv_count_in_bc" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "verified",
      dataIndex: "verified",
      key: "verified",
      filteredValue: [search],
      render: text => text ? 'YES': 'NO',
      sorter: (a, b) => sortAz09(a.verified, b.verified),
      sortOrder:
        sortedInfo.columnKey === "verified" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment",
      filteredValue: [search],
      filters: extractUniqueValues(dataPageCurrent, "payment_method"),

      sorter: (a, b) => sortAz09(a.payment_method, b.payment_method),
      sortOrder: sortedInfo.columnKey === "payment" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      minWidth: "150px",
    },
    {
      title: "currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => sortAz09(a.currency, b.currency),
      sortOrder: sortedInfo.columnKey === "currency" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "creator_id",
      dataIndex: "creator_id",
      key: "creator_id",
      sorter: (a, b) => sortAz09(a.creator_id, b.creator_id),
      sortOrder:
        sortedInfo.columnKey === "creator_id" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      filteredValue: [search],
      with: "450",
    },
    {
      title: "role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => sortAz09(a.role, b.role),
      sortOrder: sortedInfo.columnKey === "role" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: [search],
      align: "center",
      minWidth: "150px",
    },
    {
      title: "2FA",
      dataIndex: "pass_two_step_verify",
      key: "pass_two_step_verify",
      render: (text, record) => (record ? "Yes" : "No"),
      sorter: (a, b) =>
        sortAz09(a.pass_two_step_verify, b.pass_two_step_verify),
      sortOrder:
        sortedInfo.columnKey === "pass_two_step_verify"
          ? sortedInfo.order
          : null,
      ellipsis: true,
      align: "center",
      filteredValue: [search],
      minWidth: "150px",
    },
    {
      title: "timezone",
      dataIndex: "timezone",
      key: "timezone",
      sorter: (a, b) => sortAz09(a.timezone, b.timezone),
      sortOrder: sortedInfo.columnKey === "timezone" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      filteredValue: [search],
      minWidth: "150px",
    },
    {
      title: "create_time",
      dataIndex: "create_time",
      key: "create_time",
      sorter: (a, b) => sortAz09(a.create_time, b.create_time),
      sortOrder:
        sortedInfo.columnKey === "create_time" ? sortedInfo.order : null,
      ellipsis: true,
      align: "center",
      filteredValue: [search],
      minWidth: "150px",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataPageCurrent}
        onChange={handleChange}
        tableLayout="auto"
        pagination={pagination}
        size="small"
        scroll={{
          x: false,
        }}
      />
    </>
  );
};

export default TableBC;
