import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

function LandingHeader() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <div className="navbar">
          <div className="logo">
            <a href="/">
              <img src="assets/image/logo-while.png" alt="logo" />
            </a>
          </div>
          <div className="menu">
            <a className="button" href="/app">Use now</a>
          </div>
        </div>
      </header>
    </>
  );
}

export default LandingHeader;
